<template>
  <router-link to="/product/abc">
    <div class="flex flex-col h-full">
      <div class="flex flex-col flex-grow border mb-4 p-4">
        <img :src="coverUrl" alt="" />
        <p class="mb-4 p-cta-xs">{{ title }}</p>
        <p class="mt-auto p-cta-base text-xl">{{ priceText }}</p>
      </div>

      <TrButton class="mt-auto"> Bestel nu </TrButton>
    </div>
  </router-link>
</template>

<script>
import TrButton from '@/components/TrButton';

export default {
  name: 'TrProductShowItem',

  components: {
    TrButton,
  },

  props: {
    price: {
      type: Number,
    },
    title: {
      type: String,
    },
    coverUrl: {
      type: String,
    },
  },

  computed: {
    priceText() {
      const amount = new Intl.NumberFormat('nl-NL', {
        style: 'currency',
        currency: 'EUR',
      }).format(this.price / 100);

      return amount;
    },
  },
};
</script>
