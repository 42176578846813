<template>
  <div class="h-screen flex flex-col">
    <TrHeader :isSticky="true" />

    <div class="flex-grow overflow-auto">
      <div class="p-8 md:p-12 border-b text-center uppercase">
        <p class="mb-4 p-heading-xs">Troonz</p>
        <p class="p-heading-4">Onze winkels</p>
      </div>

      <div class="grid lg:grid-cols-2 gap-4 p-4 md:p-8 m-auto">
        <TrDealerCard />
        <TrDealerCard />
      </div>
    </div>
  </div>
</template>

<script>
import TrDealerCard from '@/components/TrDealerCard';
import TrHeader from '@/components/TrHeader';

export default {
  components: { TrDealerCard, TrHeader },

  data() {
    return {};
  },
};
</script>
