<template>
  <div
    :style="style"
    class="
      relative
      flex-grow flex-shrink-0
      min-w-full
      sm:min-w-1/2
      xl:min-w-0 xl:flex-shrink
      bg-cover bg-no-repeat bg-center
    "
    style="min-height: 450px"
  >
    <div class="absolute bottom-0 left-0 p-8 md:p-12 text-white uppercase">
      <p class="mb-4 p-heading-xs">Troonz</p>
      <p class="mb-8 p-heading-4">{{ collection.title }}</p>
      <TrButton theme="secondary"> Bekijk de producten </TrButton>
    </div>
  </div>
</template>

<script>
import TrButton from '@/components/TrButton';

export default {
  name: 'TrCollectionShowItem',

  components: {
    TrButton,
  },

  props: {
    collection: {
      type: Object,
    },
  },

  computed: {
    style() {
      const backgroundImageUrl = this.collection?.imageUrl || '/img/hero.jpg';

      return {
        backgroundImage: `linear-gradient(
              0deg,
              rgba(0, 0, 0, 0.4),
              rgba(0, 0, 0, 0.4)
            ),
            url(${backgroundImageUrl})`,
      };
    },
  },
};
</script>
