<template>
  <div class="flex flex-col lg:flex-row lg:flex-wrap max-w-screen-xl m-auto">
    <div
      :style="style"
      class="flex-1 bg-cover bg-no-repeat min-h-40 lg:min-h-128"
      style="background-position: center 70%"
    ></div>
    <div
      class="flex flex-col flex-1 p-6 lg:p-16"
      :class="{
        'lg:order-first': isEven,
      }"
    >
      <p class="mt-auto mb-4 p-heading-xs">Ons verhaal</p>
      <p class="mb-8 p-heading-4">
        Nooit verstoren. Altijd vernieuwen en verfijnen van de traditie.
      </p>
      <p class="p-paragraph-base">
        Zes generaties van meester-vakmannen hebben Troonz gebracht waar het nu
        is – een familiebedrijf dat nog steeds gedreven is door de deugden van
        de slaap en op een bescheiden manier trots zijn op hun ambacht. Elke
        generatie heeft zijn rol gespeeld bij het vormgeven van Troonz en
        onderweg uitdagingen overwonnen.
      </p>

      <router-link to="/about">
        <p class="p-cta-xs mt-8">
          Lees meer <font-awesome-icon :icon="['fal', 'chevron-right']" />
        </p>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TrBlogShowItem',

  components: {},

  props: {
    blog: {
      type: Object,
    },
    isEven: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    style() {
      const backgroundImageUrl = this.blog?.imageUrl || '/img/hero10.jpg';

      return {
        backgroundImage: `linear-gradient(
              0deg,
              rgba(0, 0, 0, 0.2),
              rgba(0, 0, 0, 0.2)
            ),
            url(${backgroundImageUrl})`,
      };
    },
  },
};
</script>
