import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import 'tailwindcss/tailwind.css';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faBadgeCheck,
  faBars,
  faChevronRight,
  faEnvelope,
  faMapMarkerAlt,
  faPhone,
  faShippingTimed,
  faShoppingBag,
  faTimes,
  faUser,
  faUserHeadset,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(
  faBadgeCheck,
  faBars,
  faChevronRight,
  faEnvelope,
  faMapMarkerAlt,
  faPhone,
  faShippingTimed,
  faShoppingBag,
  faTimes,
  faUser,
  faUserHeadset
);

createApp(App)
  .component('font-awesome-icon', FontAwesomeIcon)
  .use(router)
  .mount('#app');
