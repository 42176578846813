export default [
  {
    title: 'Onze collecties',
    items: [
      {
        title: 'Kussens',
      },
      {
        title: 'Dekbedden',
      },
      {
        title: 'Matrassen',
      },
    ],
  },
  {
    title: 'Over Troonz',
    items: [
      {
        title: 'Ons verhaal',
      },
      {
        title: 'Bedlinnen',
      },
      {
        title: 'Natuurlijke materialen',
      },
      {
        title: 'Carriere',
      },
    ],
  },
  {
    title: 'Klantenservice',
    items: [
      {
        title: 'Veelgestelde vragen',
      },
      {
        title: 'Verzenden en retouren',
      },
      {
        title: 'Contact',
      },
      {
        title: 'Privacybeleid',
      },
      {
        title: 'Algemene voorwaarden',
      },
    ],
  },
  {
    title: 'Lees meer over...',
    items: [
      {
        title: 'De beste (lig)houding',
      },
      {
        title: 'Kussen keuzehulp',
      },
      {
        title: 'Welk dekbed past bij jou?',
      },
    ],
  },
];
