<template>
  <div>
    <p class="mb-4 p-heading-xs">{{ title }}</p>

    <a
      v-for="(item, index) in items"
      :key="`item-${index}`"
      href="#"
      class="block mb-2 transition-all hover:text-blue-300"
    >
      {{ item.title }}
    </a>
  </div>
</template>

<script>
export default {
  name: 'TrFooterBlock',

  components: {},

  props: {
    title: {
      type: String,
    },
    items: {
      type: Array,
    },
  },
};
</script>
