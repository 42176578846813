<template>
  <TrHeader :isSticky="true" />

  <div class="w-full sm:p-16">
    <TrBlogShowItem :blog="{ imageUrl: '/img/hero21.jpg' }" />
  </div>

  <div class="w-full sm:p-16">
    <TrBlogShowItem :blog="{ imageUrl: '/img/hero22.jpg' }" :isEven="true" />
  </div>

  <div class="w-full sm:p-16">
    <TrBlogShowItem :blog="{ imageUrl: '/img/hero23.jpg' }" />
  </div>

  <TrFooter :blocks="blocks" />
</template>

<script>
import blocks from '@/blocks.js';

import TrBlogShowItem from '@/components/TrBlogShowItem';
import TrFooter from '@/components/TrFooter';
import TrHeader from '@/components/TrHeader';

export default {
  name: 'About',

  components: {
    TrBlogShowItem,
    TrFooter,
    TrHeader,
  },

  data() {
    return {
      blocks,
    };
  },
};
</script>
