<template>
  <div
    class="
      block
      cursor-pointer
      transition-all
      px-4
      py-3
      p-cta-xs
      text-center
      border-2
      hover:bg-transparent
    "
    :class="{
      'bg-gray-900 text-white border-black hover:text-black hover:border-gray-900':
        theme == 'primary',
      'bg-white text-black border-white hover:text-white hover:border-white':
        theme == 'secondary',
    }"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'TrButton',

  components: {},

  props: {
    theme: {
      type: String,
      default: 'primary',
    },
  },
};
</script>
