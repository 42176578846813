<template>
  <div class="p-4 py-8 rounded bg-gray-100">
    <!-- style="background-image: url(/img/store-hero-1.jpg)" -->
    <div class="flex mb-4 h-32 md:h-60 rounded bg-cover bg-center bg-image-1">
      <p class="p-heading-5 m-auto text-white text-center">Uniek slapen</p>
    </div>

    <div class="grid md:grid-cols-2 gap-4 mb-4">
      <div class="my-auto p-8 font-light">
        <div class="flex mb-4">
          <font-awesome-icon
            :icon="['fal', 'map-marker-alt']"
            class="p-3 fa-fw text-5xl"
          />
          <div>
            <p>Gasthuisstraat 1E</p>
            <p>3771 CE, Barneveld</p>
          </div>
        </div>

        <div class="flex mb-4">
          <font-awesome-icon
            :icon="['fal', 'phone']"
            class="p-3 fa-fw text-5xl"
          />
          <p class="my-auto">0342-434059</p>
        </div>

        <div class="flex">
          <font-awesome-icon
            :icon="['fal', 'envelope']"
            class="p-3 fa-fw text-5xl"
          />
          <p class="my-auto">info@uniekslapen.nl</p>
        </div>
      </div>

      <div class="p-8 rounded bg-gray-200">
        <p class="p-cta-xs mb-4 text-center">Openingstijden</p>

        <div class="grid grid-cols-2 gap-x-4 font-light">
          <p>Maandag</p>
          <p>09:00 - 17:00</p>
          <p>Dinsdag</p>
          <p>09:00 - 17:00</p>
          <p>Woensdag</p>
          <p>09:00 - 17:00</p>
          <p>Donderdag</p>
          <p>09:00 - 17:00</p>
          <p>Vrijdag</p>
          <p>09:00 - 17:00</p>
          <p>Zaterdag</p>
          <p>10:00 - 17:00</p>
          <p>Zondag</p>
          <p>Gesloten</p>
        </div>
      </div>
    </div>

    <iframe
      src="https://maps.google.nl/maps?f=q&amp;source=s_q&amp;hl=nl&amp;geocode=&amp;q=Uniek+Slapen+Barneveld&amp;ie=UTF8&amp;z=14&amp;iwloc=A&amp;output=embed"
      height="320"
      width="100%"
      class="rounded"
    ></iframe>
  </div>
</template>

<script>
export default {};
</script>

<style>
.bg-image-1 {
  background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.4),
      rgba(0, 0, 0, 0.4)
    ),
    url(/img/store-hero-2.jpg);
}
</style>
