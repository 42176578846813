<template>
  <div class="border-t">
    <div
      :class="{
        'border-b': isActive,
      }"
      class="flex p-4 py-6 p-cta-xs cursor-pointer"
      @click="isActive = !isActive"
    >
      <span>
        {{ title }}
      </span>

      <font-awesome-icon
        :icon="['fal', 'chevron-right']"
        :class="{
          'rotate-90': isActive,
        }"
        class="m-auto mr-0 transform transition"
      />
    </div>
    <transition name="slide">
      <div v-if="isActive">
        <div class="p-4">
          <slot></slot>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
// import TrButton from '@/components/TrButton';

export default {
  name: 'TrCarouselItem',

  components: {},

  props: {
    title: {
      type: String,
    },
  },

  data() {
    return {
      isActive: false,
    };
  },
};
</script>

<style>
.slide-enter-active {
  transition: max-height 0.5s;
}
.slide-leave-active {
  transition: max-height 0.3s, padding 0.3s ease-in 0.2s,
    margin 0.3s ease-in 0.2s, border 0.3s ease-in 0.2s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}
.slide-enter-to,
.slide-leave-from {
  max-height: 100vh;
  overflow: hidden;
}
.slide-enter-from,
.slide-leave-to {
  overflow: hidden;
  padding: 0;
  margin: 0;
  border: 0;
  max-height: 0;
}
</style>
