<template>
  <div
    class="z-10 w-full px-4 border-b text-white transition-all duration-500"
    :class="{
      'py-6': isScrolledToTop,
      'bg-gray-900 border-black': !isScrolledToTop,
      fixed: !isSticky,
      'sticky top-0': isSticky,
    }"
  >
    <div
      v-if="isDrawerOpen"
      class="fixed top-0 bottom-0 left-0 right-0 bg-black bg-opacity-50"
      @click="isDrawerOpen = false"
    ></div>
    <transition name="fly-left-toggle">
      <div v-if="isDrawerOpen" class="fixed top-0 bottom-0 left-0">
        <div
          class="flex flex-col h-full bg-gray-900 p-4"
          style="width: 300px; max-width: 90vw"
        >
          <font-awesome-icon
            :icon="['fal', 'times']"
            class="ml-auto mb-8 text-2xl cursor-pointer"
            @click="isDrawerOpen = false"
          />

          <div
            v-for="(menuItem, index) in menuItems"
            :key="`menuItem-${index}`"
            class="
              flex
              p-4
              py-6
              border-b border-gray-800
              p-cta-xs
              cursor-pointer
            "
          >
            {{ menuItem.title }}

            <font-awesome-icon
              :icon="['fal', 'chevron-right']"
              class="m-auto mr-0"
            />
          </div>

          <p class="mt-auto mb-4 px-4 p-heading-xs">Klantenservice</p>
        </div>
      </div>
    </transition>

    <div class="flex-row hidden lg:flex uppercase">
      <div class="flex" style="flex: 1 1 100%">
        <div class="p-4 p-cta-xs">Kussens</div>
        <div class="p-4 p-cta-xs">Dekbedden</div>
        <router-link :to="{ name: 'Dealers' }">
          <div class="p-4 p-cta-xs">Vind onze winkels</div>
        </router-link>
      </div>

      <img :src="logoUrl" alt="" class="m-auto" style="height: 1.75rem" />

      <div class="flex justify-end" style="flex: 1 1 100%">
        <div class="p-4 p-cta-xs">Account</div>
        <div class="p-4 p-cta-xs">Zoeken</div>
      </div>
    </div>

    <div class="flex lg:hidden">
      <div style="flex: 1 1 100%">
        <font-awesome-icon
          :icon="['fal', 'bars']"
          class="p-3 text-5xl"
          @click="isDrawerOpen = true"
        />
      </div>

      <img :src="logoUrl" alt="" class="m-auto" style="height: 1.5rem" />

      <div class="flex justify-end" style="flex: 1 1 100%">
        <font-awesome-icon :icon="['fal', 'user']" class="p-3 text-5xl" />
        <font-awesome-icon
          :icon="['fal', 'shopping-bag']"
          class="p-3 text-5xl"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TrHeader',

  props: {
    shouldChangeThemeOnScroll: {
      type: Boolean,
      default: false,
    },
    isSticky: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isDrawerOpen: false,
      initialized: false,
      scrollTop: null,
      menuItems: [
        { title: 'Kussens' },
        { title: 'Dekbedden' },
        { title: 'Matrassen' },
        { title: 'Vind onze winkels' },
      ],
    };
  },

  computed: {
    isScrolledToTop() {
      if (!this.initialized) {
        return false;
      }

      if (this.shouldChangeThemeOnScroll) {
        return this.scrollTop < 100;
      }

      return false;
    },
    logoUrl() {
      if (this.isScrolledToTop) {
        return '/img/logos/text-white.svg';
      } else {
        return '/img/logos/icon-white.svg';
      }
    },
  },

  methods: {
    handleScroll(event) {
      this.scrollTop = window.scrollY;
    },
  },

  created() {
    this.$nextTick(() => {
      this.initialized = true;
    });

    if (this.shouldChangeThemeOnScroll) {
      window.addEventListener('scroll', this.handleScroll);
    }
  },

  unmounted() {
    if (this.shouldChangeThemeOnScroll) {
      window.removeEventListener('scroll', this.handleScroll);
    }
  },
};
</script>

<style>
/* BEGIN FLY RIGHT TOGGLE */
.fly-left-toggle-enter-from,
.fly-left-toggle-leave-to {
  transform: translateX(-100vw);
}
.fly-left-toggle-enter-active {
  transition: 0.5s;
}
.fly-left-toggle-leave-active {
  position: absolute;
  transition: 0.25s;
}
/* END FLY RIGHT TOGGLE */
</style>
