import { createRouter, createWebHistory } from 'vue-router';
import Home from '@/views/Home.vue';
import About from '@/views/About.vue';
import Product from '@/views/Product.vue';
import Dealers from '@/views/Dealers.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/about',
    name: 'About',
    component: About,
  },
  {
    path: '/product/:productId',
    name: 'Product',
    component: Product,
    props: true,
  },
  {
    path: '/dealers',
    name: 'Dealers',
    component: Dealers,
  },
  {
    path: '/:catchAll(.*)',
    name: '404',
    redirect: { name: 'Home' },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      // to saved scroll position of page
      // mostly after bthek button hit
      return savedPosition;
    } else {
      // to top of page
      // mostly when viewing a new page
      return { top: 0, left: 0 };
    }
  },
});

export default router;
