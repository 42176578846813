<template>
  <TrHeader :shouldChangeThemeOnScroll="true" />

  <div class="h-screen bg-cover bg-no-repeat bg-center bg-image-0">
    <div class="absolute top-0 bottom-0 left-0 right-0">
      <video
        class="h-full w-full object-cover -my-4"
        playsinline
        autoplay
        muted
        loop
      >
        <source src="/vid/troonz-reel-vertical.mp4?v=3" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
    <div class="absolute bottom-0 left-0 p-8 md:p-16 text-white">
      <p class="mb-2 p-heading-xs">Troonz</p>
      <p class="p-heading-4">Luxe kussens en luxe dekbedden</p>
    </div>
  </div>

  <div class="w-full text-center p-cta-xs bg-gray-200">
    <div
      class="
        flex flex-row flex-wrap
        place-content-evenly
        max-w-screen-xl
        m-auto
      "
    >
      <div class="p-4 flex">
        <font-awesome-icon
          :icon="['fal', 'shipping-timed']"
          class="mr-2 text-2xl"
        />
        <span class="my-auto"> 30 dagen gratis retourneren </span>
      </div>
      <div class="p-4 flex">
        <font-awesome-icon
          :icon="['fal', 'badge-check']"
          class="mr-2 text-2xl"
        />
        <span class="my-auto"> Twee jaar garantie </span>
      </div>
      <div class="p-4 flex">
        <font-awesome-icon
          :icon="['fal', 'user-headset']"
          class="mr-2 text-2xl"
        />
        <span class="my-auto"> Écht klantcontact </span>
      </div>
    </div>
  </div>

  <div class="w-full -mt-px bg-white">
    <div class="p-8 md:p-12 text-center uppercase">
      <p class="mb-4 p-heading-xs">Troonz</p>
      <p class="p-heading-4">Onze collectie</p>
    </div>

    <div
      class="
        flex flex-wrap
        xl:flex-nowrap
        p-6
        sm:p-0
        gap-6
        sm:gap-0
        max-w-screen-xl
        m-auto
      "
    >
      <TrCollectionShowItem
        :collection="{ title: 'Kussens', imageUrl: '/img/hero1.jpg' }"
      />
      <TrCollectionShowItem
        :collection="{ title: 'Dekbedden', imageUrl: '/img/hero2.jpg' }"
      />
      <TrCollectionShowItem
        :collection="{ title: 'Matrassen', imageUrl: '/img/hero3.jpg' }"
      />
    </div>
  </div>

  <TrShowcase
    class="max-w-screen-xl m-auto"
    v-for="(showcase, index) in showcases"
    :key="`showcase-${index}`"
    :title="showcase.title"
    :subtitle="showcase.subtitle"
    :ctaText="showcase.ctaText"
    :products="showcase.products"
  />

  <div class="w-full sm:p-16 border-b">
    <TrBlogShowItem />
  </div>

  <div class="w-full p-16 border-b">
    <div
      class="
        flex flex-col
        md:flex-row md:flex-wrap
        gap-4
        max-w-screen-xl
        m-auto
      "
    >
      <TrButton class="flex-1 truncate"> Kussens </TrButton>
      <TrButton class="flex-1 truncate"> Dekbedden </TrButton>
      <TrButton class="flex-1 truncate"> Matrassen </TrButton>
    </div>
  </div>

  <TrFooter :blocks="blocks" />
</template>

<script>
import blocks from '@/blocks.js';

import TrBlogShowItem from '@/components/TrBlogShowItem';
import TrButton from '@/components/TrButton';
import TrCollectionShowItem from '@/components/TrCollectionShowItem';
import TrFooter from '@/components/TrFooter';
import TrHeader from '@/components/TrHeader';
import TrShowcase from '@/components/TrShowcase';

export default {
  name: 'Home',

  components: {
    TrBlogShowItem,
    TrButton,
    TrCollectionShowItem,
    TrFooter,
    TrHeader,
    TrShowcase,
  },

  data() {
    return {
      showcases: [
        {
          title: 'De hoofdkussen collectie',
          subtitle: 'Aanbevolen collectie',
          ctaText: 'Bekijk collectie',
          products: [
            {
              price: 9900,
              title: 'Traagschuim Deluxe',
              coverUrl: '/img/kussens/traag.jpeg',
            },
            {
              price: 5500,
              title: 'Warm&Cold',
              coverUrl: '/img/kussens/warmkoud.jpeg',
            },
            {
              price: 3500,
              title: 'Basic',
              coverUrl: '/img/kussens/basic.jpeg',
            },
            {
              price: 5500,
              title: 'Dons Deluxe',
              coverUrl: '/img/kussens/dons.jpeg',
            },
          ],
        },
        {
          title: 'De dekbedden collectie',
          subtitle: 'Aanbevolen collectie',
          ctaText: 'Bekijk collectie',
          products: [
            {
              price: 9900,
              title: 'Traagschuim Deluxe',
              coverUrl: '/img/kussens/traag.jpeg',
            },
            {
              price: 5500,
              title: 'Warm&Cold',
              coverUrl: '/img/kussens/warmkoud.jpeg',
            },
            {
              price: 3500,
              title: 'Basic',
              coverUrl: '/img/kussens/basic.jpeg',
            },
            {
              price: 5500,
              title: 'Dons Deluxe',
              coverUrl: '/img/kussens/dons.jpeg',
            },
          ],
        },
        {
          title: 'De mtrassen collectie',
          subtitle: 'Aanbevolen collectie',
          ctaText: 'Bekijk collectie',
          products: [
            {
              price: 9900,
              title: 'Aurora',
              coverUrl: '/img/matrassen/aurora.jpg',
            },
            {
              price: 5500,
              title: 'Dualfoam',
              coverUrl: '/img/matrassen/dualfoam.jpg',
            },
            {
              price: 3500,
              title: 'Ergopur',
              coverUrl: '/img/matrassen/ergopur.jpg',
            },
            {
              price: 5500,
              title: 'Fortune',
              coverUrl: '/img/matrassen/fortune.jpg',
            },
          ],
        },
      ],
      blocks,
    };
  },
};
</script>

<style>
.p-heading-1,
.p-heading-2,
.p-heading-3,
.p-heading-4,
.p-heading-5,
.p-heading-xs,
.p-cta-base,
.p-cta-xs {
  font-family: Montserrat, sans-serif;
  font-weight: 300;
  font-style: normal;
  letter-spacing: 0.2em;
  text-transform: uppercase;
}

.p-heading-1 {
  font-size: 2rem;
}

.p-heading-2 {
  font-size: 1.75rem;
}

.p-heading-3 {
  font-size: 1.5rem;
}

.p-heading-4 {
  font-size: 1.25rem;
}

.p-heading-5 {
  font-size: 1rem;
}

.p-heading-xs {
  font-size: 0.75rem;
}

.p-cta-base {
  font-size: 1rem;
}

.p-cta-xs {
  font-size: 0.75rem;
}

.p-paragraph-base {
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 400;
  font-style: normal;
  line-height: 1.65;
}

.bg-image-0 {
  background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.6),
      rgba(0, 0, 0, 0.6)
    ),
    url(/img/hero0.jpg);
}
</style>
