<template>
  <div class="w-full p-16 bg-gray-900 text-white">
    <div
      class="
        grid grid-cols-1
        sm:grid-cols-2
        lg:grid-cols-4
        gap-4 gap-y-8
        max-w-screen-xl
        m-auto
      "
    >
      <TrFooterBlock
        v-for="(block, index) in blocks"
        :key="`block-${index}`"
        :title="block.title"
        :items="block.items"
      />
    </div>
  </div>
</template>

<script>
import TrFooterBlock from '@/components/TrFooterBlock';

export default {
  name: 'TrFooter',

  components: {
    TrFooterBlock,
  },

  props: {
    blocks: {
      type: Array,
    },
  },
};
</script>
