<template>
  <div class="px-16 2xl:px-0 py-16 border-b text-center uppercase">
    <p v-if="subtitle" class="mb-4 p-heading-xs">{{ subtitle }}</p>
    <p v-if="title" class="p-heading-4">{{ title }}</p>

    <div
      class="grid mt-16 grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 gap-y-8"
    >
      <TrProductShowItem
        v-for="(product, index) in products"
        :key="`product-${index}`"
        :price="product.price"
        :title="product.title"
        :coverUrl="product.coverUrl"
      />
    </div>

    <TrButton class="mt-16 mx-auto w-max">{{ ctaText }}</TrButton>
  </div>
</template>

<script>
import TrButton from '@/components/TrButton';
import TrProductShowItem from '@/components/TrProductShowItem';

export default {
  name: 'TrShowcase',

  components: {
    TrButton,
    TrProductShowItem,
  },

  props: {
    title: {
      type: String,
    },
    subtitle: {
      type: String,
    },
    ctaText: {
      type: String,
    },
    products: {
      type: Array,
    },
  },
};
</script>
