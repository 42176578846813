<template>
  <TrHeader :isSticky="true" />

  <div class="w-full p-8 sm:p-16 border-b">
    <div
      class="
        flex flex-col
        md:flex-row md:flex-wrap
        max-w-screen-xl
        m-auto
        gap-8
        md:gap-16
        lg:gap-32
      "
    >
      <div
        class="
          flex flex-row
          md:flex-col
          flex-1
          gap-8
          overflow-auto
          rounded-sm
          border
          md:border-0
        "
      >
        <img
          class="h-full m-auto p-8 rounded-sm md:border"
          src="/img/kussens/warmkoud/1.jpg"
          alt=""
        />
        <img
          class="h-full m-auto p-8 rounded-sm md:border"
          src="/img/kussens/warmkoud/2.jpg"
          alt=""
        />
        <img
          class="h-full m-auto p-8 rounded-sm md:border"
          src="/img/kussens/warmkoud/3.jpg"
          alt=""
        />
        <img
          class="h-full m-auto p-8 rounded-sm md:border"
          src="/img/kussens/warmkoud/4.jpg"
          alt=""
        />
        <img
          class="h-full m-auto p-8 rounded-sm md:border"
          src="/img/kussens/warmkoud/5.jpg"
          alt=""
        />
      </div>
      <div class="flex-1">
        <p class="mb-4 p-heading-xs">Troonz kussens</p>
        <p class="p-heading-4">Traagschuim Deluxe Hoofdkussen</p>
        <p class="mt-4 p-cta-base text-xl">{{ priceText }}</p>

        <TrButton class="my-8"> Bestel nu </TrButton>

        <p class="my-8 p-paragraph-base">
          De meerlaagse constructie van de Angel collectie laat het ingewikkelde
          vakmanschap van het automatische uurwerk zien.
        </p>

        <TrCarouselItem title="Beschrijving">
          <p class="p-paragraph-base">
            De meerlaagse constructie van de Angel collectie laat het
            ingewikkelde vakmanschap van het automatische uurwerk zien. In
            combinatie met zorgvuldig gecoördineerde materialen en afwerkingen
            geeft dit deze uurwerken een exclusieve esthetiek met een luxueuze
            uitstraling en uitzonderlijke prestaties. Als u meer van uw horloge
            wilt, zowel qua stijl als qua prestaties, dan is de Angel-collectie
            iets voor u. De over-engineering constructie van het uurwerk, de
            wijzerplaat en de kast levert een slijtvast uurwerk op dat door
            alles heen kan. Tegelijkertijd blijft het ontwerp verfijnd en wordt
            er niet ingeleverd op de luxe uitstraling of het gevoel.
          </p>
        </TrCarouselItem>
        <TrCarouselItem title="Specificaties">
          <p class="p-paragraph-base">
            De meerlaagse constructie van de Angel collectie laat het
            ingewikkelde vakmanschap van het automatische uurwerk zien. In
            combinatie met zorgvuldig gecoördineerde materialen en afwerkingen
            geeft dit deze uurwerken een exclusieve esthetiek met een luxueuze
            uitstraling en uitzonderlijke prestaties. Als u meer van uw horloge
            wilt, zowel qua stijl als qua prestaties, dan is de Angel-collectie
            iets voor u. De over-engineering constructie van het uurwerk, de
            wijzerplaat en de kast levert een slijtvast uurwerk op dat door
            alles heen kan. Tegelijkertijd blijft het ontwerp verfijnd en wordt
            er niet ingeleverd op de luxe uitstraling of het gevoel.
          </p>
        </TrCarouselItem>
      </div>
    </div>
  </div>

  <TrShowcase
    class="max-w-screen-xl m-auto"
    :title="showcase.title"
    :subtitle="showcase.subtitle"
    :ctaText="showcase.ctaText"
    :products="showcase.products"
  />

  <TrButton class="fixed bottom-0 w-full"> Bestel nu </TrButton>

  <TrFooter :blocks="blocks" />
</template>

<script>
import blocks from '@/blocks.js';

import TrButton from '@/components/TrButton';
import TrCarouselItem from '@/components/TrCarouselItem';
import TrFooter from '@/components/TrFooter';
import TrHeader from '@/components/TrHeader';
import TrShowcase from '@/components/TrShowcase';

export default {
  name: 'Product',

  components: {
    TrButton,
    TrCarouselItem,
    TrFooter,
    TrHeader,
    TrShowcase,
  },

  props: {
    productId: {
      type: String,
    },
    price: {
      type: Number,
      default: 9900,
    },
  },

  data() {
    return {
      blocks,
      showcase: {
        title: 'Recent bekeken',
        ctaText: 'Bekijk alles',
        products: [
          {
            price: 9900,
            title: 'Traagschuim Deluxe',
            coverUrl: '/img/kussens/traag.jpeg',
          },
          {
            price: 5500,
            title: 'Warm&Cold',
            coverUrl: '/img/kussens/warmkoud.jpeg',
          },
          {
            price: 3500,
            title: 'Basic',
            coverUrl: '/img/kussens/basic.jpeg',
          },
          {
            price: 5500,
            title: 'Dons Deluxe',
            coverUrl: '/img/kussens/dons.jpeg',
          },
        ],
      },
    };
  },

  computed: {
    priceText() {
      const amount = new Intl.NumberFormat('nl-NL', {
        style: 'currency',
        currency: 'EUR',
      }).format(this.price / 100);

      return amount;
    },
  },
};
</script>
